@font-face {
  font-family: 'Nunito SemiBold';
  src: url(45ea1be74e8fb8d959b822c952ffe9ab.ttf) format('woff2');
}

@font-face {
  font-family: 'Nunito Regular';
  src: url(09b2f37e93bedfaa2976ab75c8366c14.ttf) format('woff2');
}

@font-face {
  font-family: 'Nunito ExtraBold';
  src: url(dcfddfb7ee3436fa130b07418539cc76.ttf) format('woff2');
}

@font-face {
  font-family: 'Nunito SemiBold';
  src: url(45ea1be74e8fb8d959b822c952ffe9ab.ttf) format('woff2');
}

@font-face {
  font-family: 'Nunito Black';
  src: url(17bcf6f7bd22f390c48d26475e113e02.ttf) format('woff2');
}

@font-face {
  font-family: 'Nunito Bold';
  src: url(5b67d635abb53cc261c5e7d2d71a2212.ttf) format('woff2');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

button:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button.try-button {
  background-color: #2a79ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
}

button.try-button:active {
  transform: translateY(2px);
  box-shadow: 0px 1px 1px #00000029;
}

button.try-button:disabled {
  background-color: #d4d9db;
}

button.try-button:disabled .text {
  color: #80868b;
}

button.try-button .icon {
  height: 12px;
  width: 12px;
  margin-right: 18px;
  /* width: 16px; */
}

button.try-button .text {
  color: white;
  font-family: 'Nunito Regular';
  font-size: 10px;
}
